import React, { lazy, Suspense } from 'react';
import { Routes as ReactRoutes, Route, Link, Navigate, BrowserRouter } from 'react-router-dom';
import { Button } from '@mui/material';

import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import PageFallback from 'components/PageFallback';
import PageWip from 'components/PageWip';
import LandingLayout from 'layout/LandingLayout';
import AppLayout from 'layout/AppLayout';
import { UserRolesTypes } from 'common/user-role-types';
import { allPermissionRoles } from 'utils/auth';

/** Views */
const Onboarding = lazy(() => import('views/landing/Onboarding'));
const AuthLogin = lazy(() => import('views/dashboard/public/Login'));
const AuthLogout = lazy(() => import('views/dashboard/public/Logout'));
const ForgotPassword = lazy(() => import('views/dashboard/public/ForgotPassword'));
const WelcomePage = lazy(() => import('views/dashboard/private/WelcomePage'));
const UserRegistration = lazy(() => import('views/dashboard/private/UserRegistration'));
const UploadAndContract = lazy(() => import('views/dashboard/private/UploadAndContract'));
const ContractConfirmation = lazy(() => import('views/dashboard/private/ContractConfirmation'));
const Contracts = lazy(() => import('views/dashboard/private/Contracts'));
const ContractDetail = lazy(() => import('views/dashboard/private/ContractDetail'));
const CompleteRegistration = lazy(() => import('views/dashboard/public/CompleteRegistration'));
const AdminRoutes = lazy(() => import('views/admin/AdminRoutes'));
const Reports = lazy(() => import('views/dashboard/private/ReportsPanel'));
const RevenueReport = lazy(() => import('views/dashboard/private/RevenueReport'));
const ConciliationDetails = lazy(() => import('views/dashboard/private/RevenueReport/ConciliationDetails'));
const CorrectionReport = lazy(() => import('views/dashboard/private/CorrectionReport'));

const mountPrivateRoute = (PageView: any, path: string, permittedRoles: UserRolesTypes[]) => (
  <Route
    key={path}
    path={path}
    element={
      <PrivateRoute permittedRoles={permittedRoles}>
        <AppLayout>
          <PageView />
        </AppLayout>
      </PrivateRoute>
    }
  />
);
const Routes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<PageFallback />}>
        <ReactRoutes>
          {/* Public Routes */}
          <Route
            path="/cadastro"
            element={
              <LandingLayout>
                <Onboarding />
              </LandingLayout>
            }
          />
          <Route path="/login" element={<AuthLogin />} />
          <Route path="/logout" element={<AuthLogout />} />
          <Route path="/recuperar-senha" element={<ForgotPassword />} />
          <Route path="/finalizar-cadastro" element={<CompleteRegistration />} />
          <Route
            path="/soon"
            element={
              <PageWip>
                <Link to="/" style={{ textDecoration: 'none', marginTop: 10 }}>
                  <Button variant="contained" color="secondary">
                    Ir para início
                  </Button>
                </Link>
              </PageWip>
            }
          />

          {/* Private Routes */}
          {[
            [WelcomePage, '/bem-vindo', allPermissionRoles],
            [UserRegistration, '/primeiro-acesso/usuarios/novo', allPermissionRoles],
            [UploadAndContract, '/contratar', allPermissionRoles],
            [ContractConfirmation, '/confirmacao', allPermissionRoles],
            [Contracts, '/consulta-contratos', allPermissionRoles],
            [ContractDetail, '/contratos/:id', allPermissionRoles],
            [AdminRoutes, '/admin/*', [UserRolesTypes.ADMIN]],
            [Reports, '/relatorios', allPermissionRoles],
            [RevenueReport, '/relatorios/repasses', allPermissionRoles],
            [ConciliationDetails, '/relatorios/repasses/:revenueId', allPermissionRoles],
            [CorrectionReport, '/relatorios/ajustes', allPermissionRoles],
          ].map(([View, path, roles]) => mountPrivateRoute(View, path as string, roles as UserRolesTypes[]))}

          {/* Default Route match */}
          <Route path="*" element={<Navigate to="/login" />} />
        </ReactRoutes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routes;
