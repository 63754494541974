import React, { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useNavigate } from 'react-router';

import PageFallback from 'components/PageFallback';
import { UserRolesTypes } from 'common/user-role-types';
import { isUserAdmin, userHasPermissions } from 'utils/auth';

interface PrivateRouteProps {
  children: JSX.Element;
  permittedRoles: UserRolesTypes[];
}

const PrivateRoute = ({ children, permittedRoles }: PrivateRouteProps) => {
  const { keycloak, initialized } = useKeycloak();
  const navigate = useNavigate();

  const handlePermissionToAccess = () => {
    if (!userHasPermissions(permittedRoles)) {
      if (isUserAdmin()) {
        navigate('/admin');
      } else {
        navigate('/contratar');
      }
    }
  };

  useEffect(() => {
    if (initialized) {
      if (!keycloak.authenticated) {
        navigate('/logout');
      } else {
        handlePermissionToAccess();
      }
    }
  }, [keycloak, initialized]);

  if (!initialized) return <PageFallback />;

  return children;
};

export default PrivateRoute;
